import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./blockExperience.scss"

const BlockExperience = () => {
  const dataGraphQl = useStaticQuery(graphql`
    query Reassurance {
      allTaxonomyTermReassurance {
        nodes {
          field_titre
          relationships {
            field_picto {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `)
  const data = dataGraphQl.allTaxonomyTermReassurance.nodes.map(el => ({
    label: el.field_titre,
    icon: {
      path: el.relationships.field_picto.localFile.publicURL,
    },
  }))
  return (
    <div className="wrapper">
      <ul className="block-experience br-22 bc-2 d-f fd-md-c jc-c ai-fs py-5 px-7">
        {data.map((el, index) => (
          <li className="d-f fd-c ai-c jc-c li" key={index}>
            <div
              className={"d-f ai-c"}
              style={{ height: "70px", width: "70px" }}
            >
              <img
                src={el.icon.path}
                alt="icon"
                className="w-100 h-100 ob-contain"
              />
            </div>
            <p className="ta-c mb-0 ff-gm fs-14 lh-24 cg-7">{el.label}</p>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default BlockExperience
