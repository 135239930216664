import React, { useEffect, useState } from "react"
import "./index.scss"

const BlockSecurite = ({ data }) => {
  const [hide, setHide] = useState(false)
  const [scrollTop, setScrollTop] = useState(false)
  const [temp, setTemp] = useState(false)
  useEffect(() => {
    if (!hide && temp) {
      setScrollTop(true)
      setTemp(false)
      setTimeout(() => {
        setScrollTop(false)
      }, 500)
    }
  }, [hide])
  if (data) {
    return (
      <div className="wrapper py-4">
        <div
          dangerouslySetInnerHTML={{
            __html:
              data.field_titre_block_securite_.value +
              `<p>${data.field_sous_titre_block_securite}</p>`,
          }}
          // className="d-f jc-c ta-c txt-bold span-cg-15"
          className="d-f fd-c jc-c ta-c txt-bold span-c1 mb-4"
        ></div>
        {/* <h3 className="d-f jc-c ta-c mb-5">
          {data.field_sous_titre_block_securite}
        </h3> */}
        <div
          className="block-wording"
          dangerouslySetInnerHTML={{
            __html: data.field_description_block_securite.value,
          }}
        ></div>

        <div className={`block-wording-hide ${hide ? "is-active" : ""} `}>
          <div
            className="block-wording"
            dangerouslySetInnerHTML={{
              __html: data.field_description_securite_hide.value,
            }}
          ></div>
        </div>
        <div className="d-f jc-c py-2">
          <button
            onClick={() => {
              setTemp(hide)
              setHide(!hide)
            }}
            className={
              hide
                ? "btn-show-more btn-select-round c-p d-f fd-r ai-c is-active"
                : "btn-show-more btn-select-round c-p d-f fd-r ai-c"
            }
            ref={el => {
              if (el) {
                if (scrollTop) {
                  setTimeout(() => {
                    el.scrollIntoView({
                      behavior: "smooth",
                      block: "end",
                    })
                  }, 250)
                }
              }
            }}
          >
            {hide ? "Afficher moins" : "En savoir plus"}
            <span className="icon-arrow-down-c1 ml-1 arrow" />
          </button>
        </div>
      </div>
    )
  }
  return <></>
}

export default BlockSecurite
